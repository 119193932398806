.my-profile {
    padding-top: 10px;
}

.modal-body {
    overflow: auto;
    overflow-wrap: break-word;
    font-size: 15px;
}

#integration {
    text-align: right;
    padding-right: 10px;
}

.avatar {
    padding-bottom: 125px;
}

.index_td {
    width: 100px;
    text-align: center;
}


.icon:hover {
    cursor: pointer;
}

.credits-sub-text {
    color: #636363;
    font-size: 12px;
}

.profile-assets-view {
    height: 330px;
    overflow-y: auto;
}

.profile-assets-table {
    margin: 0 auto;
    border-collapse: collapse;
    border-style: hidden;
}

.profile-assets-table td {
    padding: 1rem;
    border: 1px dashed #dee2e6;
    border-top: 1px dashed #dee2e6 !important;
}

.profile-card{
    height: 16rem;
    overflow-y: scroll;
}

.bg-row-1{
    background-color: #ffffff;
}
.bg-row-0{
    background-color: #0000000d;
}

.api-key-width{
    width: 2rem;
    background: #efefef;
    margin:0.25rem 0.5rem 0 0;
    padding: 0.25rem;
    height: 2rem;
    text-align: center;
    border-radius: 1rem;
}