.resend-btn {
    color : #FF1227;
}

.inputs{
    width: 40px;
    height: 40px
}

.otp-digit{
    letter-spacing :0.5rem;
} 

.pointer {
    cursor: pointer;
}

.support{
    color : #00AEFF;
}