.billing-plans .selected-plan {
    border-top: 3px solid var(--success);
}

.input-spinner-width {
    margin-right: -1rem;
    width: 35px !important;
    border: 0px !important;
    margin-left: 0.45rem;
}

.spinner-buttons {
    margin: 0 0rem !important;
    height: 1.85rem !important;
    padding: .25rem .5rem !important;
    font-size: .875rem !important;
}

.nav-tabs {
    border-bottom: 0px solid #dee2e6 !important;
}

.nav-link {
    padding: 0rem 1rem 0rem 0rem !important;
}

.btn-group button:first-child {
    /* border-radius: 3rem 0 0 3rem; */
}

.btn-group button:last-child {
    /* border-radius: 0 3rem 3rem 0; */
    /* margin-left: 3px; */
}

.btn-group button:only-child {
    border-radius: 6px;
    margin-left: 0;
}

.country.dropdown-menu {
    right: 0rem;
    left: unset;
}

.invoice-action-buttons {
    width: 2rem !important;
    border-radius: 1rem !important;
}