.glyphicon-envelope, .glyphicon-ok, .glyphicon-remove, #star {
    padding-top: 10px;
}

.glyphicon-log-out {
    padding-top: 2px;
}

.header {
    font-size: 18px;
}

.value {
    font-size: 30px;
}

.headings {
    text-align: left;
}

.text {
    padding-top: 15px;
}

.navigation {
    padding-top: 5px;
}

.text-small {
    font-size: 12px;
}

.people-wrapper {
    display: flex;
    flex-direction: column;
    height: inherit;
}

.people-container {
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
}

.people-container .people-list-container {
    display: -webkit-flex;
    display: flex;
    flex-grow: 1;
}

.people-container .people-list-container .people-list {
    flex-grow: 1;
}

.people-container .people-list-container .people-list .people-list-content {
    height: 100%;
    margin-left: 10px;
}

.people-container .people-list-container .filter-section {
    width: 240px;
    height: 100%;
}

.people-container .people-list-container .people-list .people-list-content .panel-body {
    height: inherit;
}

.people-container .people-list-container .people-list .people-list-content .panel-body .table-content {
    height: inherit;
}

.segment-section .custom-control-label {
    height: 1.5rem;
}

.people-check .custom-control-label {
    height: 1.9rem;
}

.dropdown-menu {
    max-height: 300px;
    overflow: auto;
}

#modal {
    text-align: center;
}

a.link-color {
    color: #2c3e50;
}

a.link-color:hover {
    color: #2c3e50;
}

a.link-pointer {
    cursor: pointer;
}

.tagPanel {
    overflow-wrap: break-word;
}

.delete {
    cursor: pointer;
}

.clearAll {
    float: right;
    cursor: pointer;
}

.people-loading {
    background-color: #ecf0f1;
    margin: 5px;
    height: 14px;
}

.people-getting-started-main-icon {
    color: #3498db
}

.bg-filter {
    color: #00aeff!important;
    background-color: #e0f2ff;
}

.people-select-all-checkbox>input:indeterminate+label {
    background: lime;
}

.people-filter-label {
    padding-top: .3rem!important;
}

.hide-segments {
    width: 0%;
    opacity: 0;
    transition: width 200ms ease-in-out 100ms;
}

.show-segments {
    width: 25%;
    opacity: 1;
    transition: all 200ms ease-in-out 100ms;
}

.hide-filters {
    height: 0;
    /* transform:scaleY(0); */
    transition: transform 300ms ease-in-out;
}

.show-filters {
    /* transform:scaleY(1); */
    transform-origin: top;
    transition: transform 300ms ease-in-out;
}

.people-table-height {
    height: 34rem !important;
}

.tooltip-inner {
    background-color: #686971 !important;
    filter: alpha(opacity=100) !important;
    opacity: 1 !important;
    border: #fff !important;
    color: rgb(32, 32, 32) !important;
  }

  .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: rgb(255, 255, 255) !important;
    margin: 100px;
  }

  .filter-line-clamp{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

 
.badge .close {
    margin-left: 0.25rem;
    color: inherit;
    font-size: 100%;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
  }

  .ReactVirtualized__Table__row:hover{
    text-decoration: none;
    background: #F4F7FC;
    border-radius: 0.5rem;
    color: var(--primary) !important;
  }