.import-summary {
    padding-top: 14px;
}

.mapped-attribute {
    border: 1px solid #5cb85c;
    padding: 5px;
    margin-right: 5px;
}

.map-selector {
    min-width: 150px;
}

.file-chooser-text {
    margin-top: 80px;
}

.header-map-correct {
    margin-left: -20px;
}

.map-attributes .dropdown-menu{
    /* width:100%; */
}
.people-import .modal-dialog{
    overflow-y: initial !important
}

.people-import .modal-body{
    /* height: calc(100vh - 120px); */
    overflow-y: auto;
}

.people-import .default-flag{
    background-image: url('/assets/images/default_flag.png');
}
.people-import .check-icon{
    min-width: 16px;
}