ol.progtrckr {
    margin: 0;
    list-style-type: none;
  }
  ol.progtrckr li {
    display: inline-block;
    cursor: pointer;
  }
  ol.progtrckr li span {
    padding: 0 1.5rem;
  }
  @media (max-width: 650px) {
    .progtrckr li span {
      display: none;
    }
  }
  .progtrckr em {
    display: none;
    font-weight: 700;
    padding-left: 1rem;
  }
  @media (max-width: 650px) {
    .progtrckr em {
      display: inline;
    }
  }

  .progress-line{
    position: relative;
    top: -0.75rem;
    width: 0%;
  }

  ol.progtrckr li.progtrckr-todo .progress-line {
    border-bottom: 4px solid var(--secondary);
    width: 100%;
    transition: all 0.5s 0.5s;
  }

  ol.progtrckr li.progtrckr-todo:last-child .progress-line {
    display: none;
  }

  
  ol.progtrckr li.progtrckr-doing:last-child .progress-line {
    display: none;
  }

  ol.progtrckr li.progtrckr-done:last-child .progress-line {
    display: none;
  }

  ol.progtrckr li.progtrckr-doing .progress-line {
    border-bottom: 4px solid var(--primary);
    width: 100%;
    transition: all 0.5s 0.5s;
  }

  ol.progtrckr li.progtrckr-done .progress-line {
    border-bottom: 4px solid var(--primary);
    width: 100%;
    transition: all 0.5s 0.5s;
  }


  ol.progtrckr li.progtrckr-todo .step-name {
    color: #fff;
    background: var(--secondary);
    background-position: right bottom;
    padding: 0.25rem 0.75rem;
    border-radius: 1.5rem;

  }

  /* temp */
  
  ol.progtrckr li.progtrckr-undefined .step-name {
    color: #fff;
    background: var(--secondary);
    background-position: right bottom;
    padding: 0.25rem 0.75rem;
    border-radius: 1.5rem;

  }

  ol.progtrckr li.progtrckr-doing .step-name {
    color: #fff;
    background: linear-gradient(to left, var(--secondary) 50%, var(--primary) 50%);
    background-size: 200% 100%;
    background-position: left bottom;
   
    padding: 0.25rem 0.75rem;
    border-radius: 1.5rem;
    transition: all 0.5s ease-out;
  }
  ol.progtrckr li.progtrckr-done .step-name {
    color: #fff;
    background: var(--primary);
    padding: 0.25rem 0.75rem;
    border-radius: 1.5rem;
  }

.step-buttons{
  position: sticky;
  bottom: -0;
  width: 80%;
  background: #fff;
  margin: 1rem 0;
}
