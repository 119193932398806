.people-action-bar .flex-container {
    display:flex;
}

.people-action-bar .flex-container .select-action-show {
    visibility: visible;
    opacity: 1;
    display: inline-flex;
    margin-bottom: 15px;
}

.people-action-bar .flex-container .select-action-hide {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, display 0s, opacity 1s linear;
    -webkit-transition: visibility 0s, display 0s, opacity 1s linear;
    display: none;
}

.people-action-bar .search {
    flex-grow: 1;
    margin: 0 15px;
}
