.add-team-member .scope-table td:not(:first-child) {
    text-align: center;
    width: 15%;
}
.add-team-member .scope-table td{
    /* width: 20%; */
}
.add-team-member .scope-table th:not(:first-child) {
    text-align: center;
}
