.multi-select-dropdown:focus{
outline: 0;
box-shadow: 0 0 0 0 #00000000;
}
.dropdown-toggle{
    padding: .25rem .5rem !important;
    font-size: .875rem !important;
    line-height: 1.5 !important;
    border-radius: .2rem !important;
}

.multi-select-dropdown button:active,.multi-select-dropdown button:focus,.multi-select-dropdown button:hover{
    background-color: inherit !important;
    color:#000 !important;
}