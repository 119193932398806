.billing-info .intl-tel-input {
    width: 100%;
}

#countrySelector {
    max-height: 200px!important;
}

.rbt-input-main {
    font-size: 0.875rem !important;
}

.form-control.is-invalid, .was-validated .form-control:invalid {
    border-color: #dc3545 !important;
}