.no-of-sms {
    position: absolute;
    left: 11.5rem;
}

.sms-credits {
    position: absolute;
    left: 16rem;
}

.total {
    position: absolute;
    left: 18.9rem;
}

.total-email {
    position: absolute;
    left: 16.9rem;
}

.spinner-buttons {
    font-size: .5rem !important;
}

.spinner-buttons.btn-dark {
    color: #212121 !important;
    background-color: transparent !important;
    border-color: transparent !important;
}

.spinner-buttons.btn-dark:hover {
    color: var(--primary) !important;
    background-color: transparent !important;
    border-color: transparent !important;
}

.package-card-1 {
    min-height: 15rem;
}

.package-card-2 {
    min-height: 106px;
}

.ReactVirtualized__Table__headerRow {
    text-transform: capitalize !important;
}

.stripe-card-container {
    height: 60px;
    width: 100% !important;
}

.opacity-p7 {
    opacity: 0.7;
}


.card-payment-container {
    height: 300px;
    overflow-y: scroll;
}

.flag-size-2x {
    transform: scale(1.2);
    margin-bottom: 0.5rem;
    margin-left: 0.5rem;
}

.purchase-box{
    background: #ced1d6;
    color: rgb(0, 0, 0);
    width: 7rem;
    height: 3rem;
    align-items: center;
    display: flex;
    justify-content: center;
    border: 0;
}

.purchase-box.active{
    background: var(--primary);
}

.active-package-label{
    /* position: absolute;
    left: 3.45rem; */
    width: 9rem;
    text-align: center;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    height: 1.75rem;
    align-content: center;
    padding-top: 0.1rem;
    border-radius: 0 0 0.25rem 0.25rem;
    color: #fff;
}


.tick-list-image {
    list-style-image: url(/assets/images/tick-sm.svg);
    /* list-style: none; */
    vertical-align: middle;
    padding-left: 1.3rem !important;
  }

  .pricing-features {
    font-size: 12px;
    text-align: left;
  }

  .per-sms-rate-text{
      font-size: 14.8px;
  }
 
  .badge-platform-offer {
    color: #0089ff;
    background-color: #e0f2ff;
    padding: 0 0.5rem;
    border-radius: 1rem;
}

.opacity-0{
    opacity: 0;
}