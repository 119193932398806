.segment-name {
    color: #00AEFF;
}

.pointer {
    cursor: pointer;
}

.segment-badge {
    border-radius: 10px !important;
}

.senderId-link {
    text-decoration: none !important;
    color: #00AEFF;
}

.segment-set .custom-control-label {
    padding-top: 0.2rem !important;
}

.hr-dashed {
    border-top: 1.5px dashed rgb(0 0 0 / 9%) !important
}

.bg-sender {
    background: #efefef;
    padding: 0.25rem;
}

.border-text-box {
    border-radius: .25rem;
    min-height: 5rem;
    padding: 0.5rem;
}

.height-39 {
    height: 39px;
}

.summary-to {
    color: #005a85;
    background-color: #ccefff;
    border-radius: 4rem !important;
    /* border: 1px solid #00aeff!important; */
}

#schedule {
    height: 39px !important;
    font-size: 15.3px !important;
}

.campaign-setup-height {
    height: 67vh;
    overflow-y: auto;
}