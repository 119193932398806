.inputs input {
    width: 40px;
    height: 40px
}

.pl-verify {
    padding-left: 0.65rem;
}

.top-left-3 {
    top: 2.4rem;
    left: 3rem;
}

.dashboard-cards {
    height: 143px;
}

.page-links {
    text-decoration: none !important;
    color: #ffffff !important;
}

.pointer {
    cursor: pointer;
}

.banner {
    border: 1px solid #3DD660 !important;
    background-color: #c5f3c3 !important;
}

.verify-banner {
    border: 1px solid #3DD660 !important;
    background-color: #c5f3c3 !important;
}

.onboarding-icon {
    width: 5rem;
}

.bg-onboarding {
    background-color: rgba(0, 0, 0, .03);
}

.alert-color-onboarding{
    color: #222222;
    background-color: #FEEDBE;
}