.top-nav-bar {
    z-index: 1;
    border-radius: 0 !important;
}

/* .top-nav-bar:after {
    content: " ";
    position: absolute;
    width: calc(100% - 166px);
    background-color: #fff;
    right: 0;
    bottom: 0;
    height: 4px;
    z-index: -1;
    -moz-box-shadow:  0px 4px 5px 0px #e8e8e8;
    -webkit-box-shadow:  0px 4px 5px 0px #e8e8e8;
    box-shadow:  0px 4px 5px 0px #e8e8e8;
} */

.top-nav-bar .nav-components {
    margin-top: 10px;
    padding: 5px;
}

.top-nav-bar .module-link {
    color: #ffffff;
}

.top-nav-bar .module-link:hover {
    color: inherit;
}

.top-nav-bar .module-link.active-link {
    background-color: #6AB3E3;
}

a.nav-link.active {
    color: #000000;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    font-weight: 600;
}

.nav-link {
    padding: 0rem 1rem !important;
}

.main-nav-container {
    position: fixed !important;
    width: 100%;
    /* margin-left: 3.8rem; */
    z-index: 100;
}

.link-text {
    padding: 7px;
    font-size: 12.8px;
    font-weight: 600;
}

.navigation-dropdown{
    font-family: 'Poppins', sans-serif !important;
}