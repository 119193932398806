.create-summary {
    padding-top: 14px;
}

.custom-attribute {
    line-height: 2;
}

.custom-attr-label {
    font-size: inherit !important;
}


.option-btn {
    color:#000;
}

.option-btn:hover,.option-btn:focus{
    text-decoration: none;
    color:#ADA9B2;
}

.sms-preview {
    white-space: pre-wrap;
}

.editor-content {
    background: #f2f2f2;
}

.channel-selector-box {
    background-color: #ffffff;
    border: 2px solid #DCE4EC;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    text-align: center;
    cursor: pointer;
    position: relative;
    border-radius: 4px;
    padding-top: 8%;
    padding-bottom: 8%;
}

.channel-selector-box:hover {
    border: 2px solid #000;
}

.channel-selector-box .selected {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(24, 188, 156, 0.7);
}

.channel-selector-box .selected .selected-icon {
    color: #fff;
}

/*campaign selection box*/
.campaign-create .selection-box {
    text-align: center;
  }
  .campaign-create .selection-box h1 {
    color: #fff;
  }

  .campaign-create .selection-box input[type="radio"] {
    display: none;
  }
  .campaign-create .selection-box input[type="radio"]:checked + .box div {
    color: var(--success);
  }

  .campaign-create .selection-box .box {
    width: 200px;
    height: 200px;
  }
  .campaign-create .selection-box .box:hover{
    color: var(--success);
  }
  .campaign-create .selection-box .box.disabled{
    cursor: not-allowed;
  }
  .campaign-create .selection-box .box.disabled:hover{
    background-color: #ecf0f1;
  }



  .campaign-create .selection-check-segment svg{
      margin-top:6px;
  }

  .campaign-create{
    height: 54rem;
    /* overflow-y: scroll; */
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }
 

  .toolbar__button {
    width: 40px !important;
    height: 34px !important;
}


.create-sender-id-button {
  background-color: #ffffff;
  border: 2px solid #DCE4EC;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  text-align: center;
  cursor: pointer;
  position: relative;
  border-radius: 4px;
  padding-top: 0;
  height: 40px;
}

.sender-id-button-text{
  padding: 8px;
}
