.scrollTable {
  /* height: 250px;    */
  overflow-y: auto;
}

.table-custom-height {
  height: 465px !important;
}

.number-purchase-modal {
  min-height: 70vh;
  max-height: 95vh;
}

.numbers-row {
  height: 41px;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 1rem !important;
}

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: var(--primary) !important;
  background-color: var(--primary) !important;
}

.payment-cards-container {
  height: 225px;
  overflow-y: auto;
}
