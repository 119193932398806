/* HIDE RADIO */
[type=radio] { 
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* IMAGE STYLES */
  [type=radio] + img {
    cursor: pointer;
  }
  
  /* CHECKED STYLES */
  [type=radio]:checked + img {
    outline: 2px solid #f00;
  }

  .card-payment .custom-control-label{
      height: 0 !important;
  }