@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
.date-range-selector{
    display: inline;
}
#data-range-selection.popover{
    max-width: none;

}
.pie-chart-widget .pie-labels{
    font-size: 9px;
}
.pie-chart-widget .pie-chart-icon{
    color: #BDBDBD;
}
.pie-chart-widget .selected-label{
    font-family: Lato,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 12px;
}
.loading-component{
    min-height: 120px;
    width: 100%;
    position: relative;
}

.loading-component .loader-content{
    background: url('/assets/images/loading.svg') no-repeat center center;
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height: 100%;
}

.home-wrapper {
    padding-top: 3.6rem;
    padding-left: 150px;
    height: inherit;
}

.home-wrapper-onboarding {
    padding-left: 275px;
    height: inherit;
}

.home-wrapper .sidebar-wrapper {
    /* z-index: 1000; */
    position: fixed;
    left: 67px;
    width: 161px;
    height: 100%;
    margin-left: -70px;
    /* background: #394F5A;*/
    display: flex;
    flex-direction: column;
    /* overflow-x: hidden; */
    overflow-y: auto; 
}


.home-wrapper .page-content-wrapper {
    position: relative;
    width: 100%;
    height: inherit;
}

.home-wrapper .sidebar-wrapper a {
    padding: 0.25rem;
}

.home-wrapper .sidebar-wrapper .nav-text {
    font-size: 8px;
}

.sidebar-wrapper > ul > li.active, .sidebar-wrapper > ul > li > a:hover, .sidebar-wrapper > ul > li > a:focus {
    background-color: #333333;
}

.home-wrapper .sidebar-wrapper a {
    color: #ffffff;
}

.home-wrapper .sidebar-wrapper .navbar-brand {
    height: 60px;
}

.home-wrapper .sidebar-wrapper .push-top {
    flex-grow: 1;
}

.credits {
    font-size: 9px;
    margin: 0;
}

.credits-text{
    color: #636363;
    font-size: 14px;
}

.credits-sub-text{
    color: #eeeeee;
    font-size: 10px;
}

.product-logo {
    text-align: center;
    padding: 10px;
}

.link {
    display: flex;
    align-items: center;
    line-height: 1;
    padding: 0.5rem !important;
    text-decoration: none;
    margin: 0.2rem 0.5rem;
    color: var(--dark) !important;
    /* -webkit-flex-direction: column;
    flex-direction: column; */
}

.link-header{
    font-size: 70%;
    margin: 0.2rem 1rem;
    color: var(--gray);
}

.link-sub {
    margin: 0.2rem 1rem;
}

.link:hover, .link:focus, .link-sub:hover, .link-sub:focus {
    text-decoration: none;
}

.link .link-text {
    padding: 7px;
    font-size: 12.8px;
}

.link-sub .link-text {
    padding: 7px;
    font-size: 12.8px;
}

.active-link {
    text-align: center;
    text-decoration: none;
    background: #F4F7FC;
    margin: 0.2rem 0.5rem;
    border-radius: 0.5rem;
    color: var(--primary) !important;
    /* border-left: 3px solid var(--primary); */
}

.sidebar-popover {
    position: absolute !important;
    left: 1rem !important;
    background: rgb(255, 255, 255) !important;
    border-radius:3px;
    min-width: 160px;
}

/* .sidebar-popover p{
    text-align: center;
    color: #ffffff;
    padding: 5px;
} */

.sidebar-popover .popover-content {
    margin: 0;
    padding: 3px 0 3px 0;
}

.sidebar-popover.popover {
    margin-left: -27px !important;
}

.sidebar-popover.popover.right > .arrow:after {
    border-right-color: #556B76;
}

.side-nav-icon{
    font-size: 0.9rem;
}

.sidebar-user-avatar{
    font-size: 12px;
}
ol.progtrckr {
    margin: 0;
    list-style-type: none;
  }
  ol.progtrckr li {
    display: inline-block;
    cursor: pointer;
  }
  ol.progtrckr li span {
    padding: 0 1.5rem;
  }
  @media (max-width: 650px) {
    .progtrckr li span {
      display: none;
    }
  }
  .progtrckr em {
    display: none;
    font-weight: 700;
    padding-left: 1rem;
  }
  @media (max-width: 650px) {
    .progtrckr em {
      display: inline;
    }
  }

  .progress-line{
    position: relative;
    top: -0.75rem;
    width: 0%;
  }

  ol.progtrckr li.progtrckr-todo .progress-line {
    border-bottom: 4px solid var(--secondary);
    width: 100%;
    transition: all 0.5s 0.5s;
  }

  ol.progtrckr li.progtrckr-todo:last-child .progress-line {
    display: none;
  }

  
  ol.progtrckr li.progtrckr-doing:last-child .progress-line {
    display: none;
  }

  ol.progtrckr li.progtrckr-done:last-child .progress-line {
    display: none;
  }

  ol.progtrckr li.progtrckr-doing .progress-line {
    border-bottom: 4px solid var(--primary);
    width: 100%;
    transition: all 0.5s 0.5s;
  }

  ol.progtrckr li.progtrckr-done .progress-line {
    border-bottom: 4px solid var(--primary);
    width: 100%;
    transition: all 0.5s 0.5s;
  }


  ol.progtrckr li.progtrckr-todo .step-name {
    color: #fff;
    background: var(--secondary);
    background-position: right bottom;
    padding: 0.25rem 0.75rem;
    border-radius: 1.5rem;

  }

  /* temp */
  
  ol.progtrckr li.progtrckr-undefined .step-name {
    color: #fff;
    background: var(--secondary);
    background-position: right bottom;
    padding: 0.25rem 0.75rem;
    border-radius: 1.5rem;

  }

  ol.progtrckr li.progtrckr-doing .step-name {
    color: #fff;
    background: linear-gradient(to left, var(--secondary) 50%, var(--primary) 50%);
    background-size: 200% 100%;
    background-position: left bottom;
   
    padding: 0.25rem 0.75rem;
    border-radius: 1.5rem;
    transition: all 0.5s ease-out;
  }
  ol.progtrckr li.progtrckr-done .step-name {
    color: #fff;
    background: var(--primary);
    padding: 0.25rem 0.75rem;
    border-radius: 1.5rem;
  }

.step-buttons{
  position: -webkit-sticky;
  position: sticky;
  bottom: -0;
  width: 80%;
  background: #fff;
  margin: 1rem 0;
}

.import-summary {
    padding-top: 14px;
}

.mapped-attribute {
    border: 1px solid #5cb85c;
    padding: 5px;
    margin-right: 5px;
}

.map-selector {
    min-width: 150px;
}

.file-chooser-text {
    margin-top: 80px;
}

.header-map-correct {
    margin-left: -20px;
}

.map-attributes .dropdown-menu{
    /* width:100%; */
}
.people-import .modal-dialog{
    overflow-y: initial !important
}

.people-import .modal-body{
    /* height: calc(100vh - 120px); */
    overflow-y: auto;
}

.people-import .default-flag{
    background-image: url('/assets/images/default_flag.png');
}
.people-import .check-icon{
    min-width: 16px;
}
.glyphicon-envelope, .glyphicon-ok, .glyphicon-remove, #star {
    padding-top: 10px;
}

.glyphicon-log-out {
    padding-top: 2px;
}

.header {
    font-size: 18px;
}

.value {
    font-size: 30px;
}

.headings {
    text-align: left;
}

.text {
    padding-top: 15px;
}

.navigation {
    padding-top: 5px;
}

.text-small {
    font-size: 12px;
}

.people-wrapper {
    display: flex;
    flex-direction: column;
    height: inherit;
}

.people-container {
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
}

.people-container .people-list-container {
    display: flex;
    flex-grow: 1;
}

.people-container .people-list-container .people-list {
    flex-grow: 1;
}

.people-container .people-list-container .people-list .people-list-content {
    height: 100%;
    margin-left: 10px;
}

.people-container .people-list-container .filter-section {
    width: 240px;
    height: 100%;
}

.people-container .people-list-container .people-list .people-list-content .panel-body {
    height: inherit;
}

.people-container .people-list-container .people-list .people-list-content .panel-body .table-content {
    height: inherit;
}

.segment-section .custom-control-label {
    height: 1.5rem;
}

.people-check .custom-control-label {
    height: 1.9rem;
}

.dropdown-menu {
    max-height: 300px;
    overflow: auto;
}

#modal {
    text-align: center;
}

a.link-color {
    color: #2c3e50;
}

a.link-color:hover {
    color: #2c3e50;
}

a.link-pointer {
    cursor: pointer;
}

.tagPanel {
    overflow-wrap: break-word;
}

.delete {
    cursor: pointer;
}

.clearAll {
    float: right;
    cursor: pointer;
}

.people-loading {
    background-color: #ecf0f1;
    margin: 5px;
    height: 14px;
}

.people-getting-started-main-icon {
    color: #3498db
}

.bg-filter {
    color: #00aeff!important;
    background-color: #e0f2ff;
}

.people-select-all-checkbox>input:indeterminate+label {
    background: lime;
}

.people-filter-label {
    padding-top: .3rem!important;
}

.hide-segments {
    width: 0%;
    opacity: 0;
    transition: width 200ms ease-in-out 100ms;
}

.show-segments {
    width: 25%;
    opacity: 1;
    transition: all 200ms ease-in-out 100ms;
}

.hide-filters {
    height: 0;
    /* transform:scaleY(0); */
    transition: -webkit-transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
}

.show-filters {
    /* transform:scaleY(1); */
    -webkit-transform-origin: top;
            transform-origin: top;
    transition: -webkit-transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
}

.people-table-height {
    height: 34rem !important;
}

.tooltip-inner {
    background-color: #686971 !important;
    filter: alpha(opacity=100) !important;
    opacity: 1 !important;
    border: #fff !important;
    color: rgb(32, 32, 32) !important;
  }

  .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: rgb(255, 255, 255) !important;
    margin: 100px;
  }

  .filter-line-clamp{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

 
.badge .close {
    margin-left: 0.25rem;
    color: inherit;
    font-size: 100%;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
  }

  .ReactVirtualized__Table__row:hover{
    text-decoration: none;
    background: #F4F7FC;
    border-radius: 0.5rem;
    color: var(--primary) !important;
  }
.people-action-bar .flex-container {
    display:flex;
}

.people-action-bar .flex-container .select-action-show {
    visibility: visible;
    opacity: 1;
    display: inline-flex;
    margin-bottom: 15px;
}

.people-action-bar .flex-container .select-action-hide {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, display 0s, opacity 1s linear;
    -webkit-transition: visibility 0s, display 0s, opacity 1s linear;
    display: none;
}

.people-action-bar .search {
    flex-grow: 1;
    margin: 0 15px;
}

.multi-select-dropdown:focus{
outline: 0;
box-shadow: 0 0 0 0 #00000000;
}
.dropdown-toggle{
    padding: .25rem .5rem !important;
    font-size: .875rem !important;
    line-height: 1.5 !important;
    border-radius: .2rem !important;
}

.multi-select-dropdown button:active,.multi-select-dropdown button:focus,.multi-select-dropdown button:hover{
    background-color: inherit !important;
    color:#000 !important;
}
.person-profile .back-btn-icon{
    color: #9AB1B8;
}

.top-nav-bar {
    z-index: 1;
    border-radius: 0 !important;
}

.top-nav-bar .nav-components {
    margin-top: 10px;
    padding: 5px;
}

.top-nav-bar .module-link {
    color: #ffffff;
}

.top-nav-bar .module-link:hover {
    color: inherit;
}

.top-nav-bar .module-link.active-link {
    background-color: #6AB3E3;
}

a.nav-link.active {
    color: #000000;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    font-weight: 600;
}

.nav-link {
    padding: 0rem 1rem !important;
}
.template-gallery-container{
    height: 35rem;
}
.template-gallery-cards{
    height: 30rem;
    overflow-y: auto;
}
.template-gallery .template-name{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.template-gallery .close{
    color:#000;
}
.template-gallery .close:hover{
    color:#000;
}


/* .rbt-aux .rbt-close {
    margin-top: 0 !important;
}

.rbt-input{
    border: none !important;
}

.rbt-input:focus{
    border-color: #00000000 !important;
    border-bottom: 3px solid var(--primary) !important;
    outline: 0;
    box-shadow: 0 0 0 00rem #00000000 !important;
} */

.bg-label{
    background: #efefef;
    padding: 0.25rem;
    margin: 0.5rem 0 -0.5rem 0;
}
.create-summary {
    padding-top: 14px;
}

.custom-attribute {
    line-height: 2;
}

.custom-attr-label {
    font-size: inherit !important;
}


.option-btn {
    color:#000;
}

.option-btn:hover,.option-btn:focus{
    text-decoration: none;
    color:#ADA9B2;
}

.sms-preview {
    white-space: pre-wrap;
}

.editor-content {
    background: #f2f2f2;
}

.channel-selector-box {
    background-color: #ffffff;
    border: 2px solid #DCE4EC;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    text-align: center;
    cursor: pointer;
    position: relative;
    border-radius: 4px;
    padding-top: 8%;
    padding-bottom: 8%;
}

.channel-selector-box:hover {
    border: 2px solid #000;
}

.channel-selector-box .selected {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(24, 188, 156, 0.7);
}

.channel-selector-box .selected .selected-icon {
    color: #fff;
}

/*campaign selection box*/
.campaign-create .selection-box {
    text-align: center;
  }
  .campaign-create .selection-box h1 {
    color: #fff;
  }

  .campaign-create .selection-box input[type="radio"] {
    display: none;
  }
  .campaign-create .selection-box input[type="radio"]:checked + .box div {
    color: var(--success);
  }

  .campaign-create .selection-box .box {
    width: 200px;
    height: 200px;
  }
  .campaign-create .selection-box .box:hover{
    color: var(--success);
  }
  .campaign-create .selection-box .box.disabled{
    cursor: not-allowed;
  }
  .campaign-create .selection-box .box.disabled:hover{
    background-color: #ecf0f1;
  }



  .campaign-create .selection-check-segment svg{
      margin-top:6px;
  }

  .campaign-create{
    height: 54rem;
    /* overflow-y: scroll; */
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }
 

  .toolbar__button {
    width: 40px !important;
    height: 34px !important;
}


.create-sender-id-button {
  background-color: #ffffff;
  border: 2px solid #DCE4EC;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  text-align: center;
  cursor: pointer;
  position: relative;
  border-radius: 4px;
  padding-top: 0;
  height: 40px;
}

.sender-id-button-text{
  padding: 8px;
}

.segment-name {
    color: #00AEFF;
}

.pointer {
    cursor: pointer;
}

.segment-badge {
    border-radius: 10px !important;
}

.senderId-link {
    text-decoration: none !important;
    color: #00AEFF;
}

.segment-set .custom-control-label {
    padding-top: 0.2rem !important;
}

.hr-dashed {
    border-top: 1.5px dashed rgb(0 0 0 / 9%) !important
}

.bg-sender {
    background: #efefef;
    padding: 0.25rem;
}

.border-text-box {
    border-radius: .25rem;
    min-height: 5rem;
    padding: 0.5rem;
}

.height-39 {
    height: 39px;
}

.summary-to {
    color: #005a85;
    background-color: #ccefff;
    border-radius: 4rem !important;
    /* border: 1px solid #00aeff!important; */
}

#schedule {
    height: 39px !important;
    font-size: 15.3px !important;
}

.campaign-setup-height {
    height: 67vh;
    overflow-y: auto;
}
#sender-request-form .form-control.rbt-input-multi.rbt-input.focus {
    height: auto;
}
#purpose .custom-control-label{
    line-height: 1.9rem !important;
}
.add-team-member .scope-table td:not(:first-child) {
    text-align: center;
    width: 15%;
}
.add-team-member .scope-table td{
    /* width: 20%; */
}
.add-team-member .scope-table th:not(:first-child) {
    text-align: center;
}

.my-profile {
    padding-top: 10px;
}

.modal-body {
    overflow: auto;
    overflow-wrap: break-word;
    font-size: 15px;
}

#integration {
    text-align: right;
    padding-right: 10px;
}

.avatar {
    padding-bottom: 125px;
}

.index_td {
    width: 100px;
    text-align: center;
}


.icon:hover {
    cursor: pointer;
}

.credits-sub-text {
    color: #636363;
    font-size: 12px;
}

.profile-assets-view {
    height: 330px;
    overflow-y: auto;
}

.profile-assets-table {
    margin: 0 auto;
    border-collapse: collapse;
    border-style: hidden;
}

.profile-assets-table td {
    padding: 1rem;
    border: 1px dashed #dee2e6;
    border-top: 1px dashed #dee2e6 !important;
}

.profile-card{
    height: 16rem;
    overflow-y: scroll;
}

.bg-row-1{
    background-color: #ffffff;
}
.bg-row-0{
    background-color: #0000000d;
}

.api-key-width{
    width: 2rem;
    background: #efefef;
    margin:0.25rem 0.5rem 0 0;
    padding: 0.25rem;
    height: 2rem;
    text-align: center;
    border-radius: 1rem;
}
.scrollTable {
  /* height: 250px;    */
  overflow-y: auto;
}

.table-custom-height {
  height: 465px !important;
}

.number-purchase-modal {
  min-height: 70vh;
  max-height: 95vh;
}

.numbers-row {
  height: 41px;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 1rem !important;
}

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: var(--primary) !important;
  background-color: var(--primary) !important;
}

.payment-cards-container {
  height: 225px;
  overflow-y: auto;
}

.billing-plans .selected-plan {
    border-top: 3px solid var(--success);
}

.input-spinner-width {
    margin-right: -1rem;
    width: 35px !important;
    border: 0px !important;
    margin-left: 0.45rem;
}

.spinner-buttons {
    margin: 0 0rem !important;
    height: 1.85rem !important;
    padding: .25rem .5rem !important;
    font-size: .875rem !important;
}

.nav-tabs {
    border-bottom: 0px solid #dee2e6 !important;
}

.nav-link {
    padding: 0rem 1rem 0rem 0rem !important;
}

.btn-group button:first-child {
    /* border-radius: 3rem 0 0 3rem; */
}

.btn-group button:last-child {
    /* border-radius: 0 3rem 3rem 0; */
    /* margin-left: 3px; */
}

.btn-group button:only-child {
    border-radius: 6px;
    margin-left: 0;
}

.country.dropdown-menu {
    right: 0rem;
    left: unset;
}

.invoice-action-buttons {
    width: 2rem !important;
    border-radius: 1rem !important;
}

.sms-country-selector {
    /* width: 20rem; */
}

.sms-country-selector-text {
    max-width: 15rem;
    font-size: 80%;
}

.sms-country-selector-text-2 {
    max-width: 20rem;
    font-size: 80%;
}


.billing-plans .dropdown-toggle::after {
    position: absolute;
    top: .6rem;
    right: 1rem;
    height: 1rem;
}

.sms-country-selector:focus {
    outline: none;
    outline-color: #00000000;
}

.sms-country-selector-item{
    background: #fff;
}

.sms-country-selector-item:hover{
    background: rgb(213, 226, 255);
}
.sms-country-selector-input{
    outline: 0;
}
.sms-country-selector-flag {
    width: 20px;
    height: 15px;
    background-image: url(/assets/images/flags.png);
    background-color: #DBDBDB;
    background-position: 0px 0;
    vertical-align: middle;
}

.scrollable-menu {
    max-height: calc(30vh);
    overflow-x: hidden;
    width: 20rem;
}



.ac {
    height: 10px;
    background-position: 0px 0px;
}

.ad {
    height: 14px;
    background-position: -22px 0px;
}

.ae {
    height: 10px;
    background-position: -44px 0px;
}

.af {
    height: 14px;
    background-position: -66px 0px;
}

.ag {
    height: 14px;
    background-position: -88px 0px;
}

.ai {
    height: 10px;
    background-position: -110px 0px;
}

.al {
    height: 15px;
    background-position: -132px 0px;
}

.am {
    height: 10px;
    background-position: -154px 0px;
}

.ao {
    height: 14px;
    background-position: -176px 0px;
}

.aq {
    height: 14px;
    background-position: -198px 0px;
}

.ar {
    height: 13px;
    background-position: -220px 0px;
}

.as {
    height: 10px;
    background-position: -242px 0px;
}

.at {
    height: 14px;
    background-position: -264px 0px;
}

.au {
    height: 10px;
    background-position: -286px 0px;
}

.aw {
    height: 14px;
    background-position: -308px 0px;
}

.ax {
    height: 13px;
    background-position: -330px 0px;
}

.az {
    height: 10px;
    background-position: -352px 0px;
}

.ba {
    height: 10px;
    background-position: -374px 0px;
}

.bb {
    height: 14px;
    background-position: -396px 0px;
}

.bd {
    height: 12px;
    background-position: -418px 0px;
}

.be {
    height: 15px;
    background-position: -440px 0px;
}

.bf {
    height: 14px;
    background-position: -460px 0px;
}

.bg {
    height: 12px;
    background-position: -482px 0px;
}

.bh {
    height: 12px;
    background-position: -504px 0px;
}

.bi {
    height: 12px;
    background-position: -526px 0px;
}

.bj {
    height: 14px;
    background-position: -548px 0px;
}

.bl {
    height: 14px;
    background-position: -570px 0px;
}

.bm {
    height: 10px;
    background-position: -592px 0px;
}

.bn {
    height: 10px;
    background-position: -614px 0px;
}

.bo {
    height: 14px;
    background-position: -636px 0px;
}

.bq {
    height: 14px;
    background-position: -658px 0px;
}

.br {
    height: 14px;
    background-position: -680px 0px;
}

.bs {
    height: 10px;
    background-position: -702px 0px;
}

.bt {
    height: 14px;
    background-position: -724px 0px;
}

.bv {
    height: 15px;
    background-position: -746px 0px;
}

.bw {
    height: 14px;
    background-position: -768px 0px;
}

.by {
    height: 10px;
    background-position: -790px 0px;
}

.bz {
    height: 14px;
    background-position: -812px 0px;
}

.ca {
    height: 10px;
    background-position: -834px 0px;
}

.cc {
    height: 10px;
    background-position: -856px 0px;
}

.cd {
    height: 15px;
    background-position: -878px 0px;
}

.cf {
    height: 14px;
    background-position: -900px 0px;
}

.cg {
    height: 14px;
    background-position: -922px 0px;
}

.ch {
    height: 15px;
    background-position: -944px 0px;
}

.ci {
    height: 14px;
    background-position: -961px 0px;
}

.ck {
    height: 10px;
    background-position: -983px 0px;
}

.cl {
    height: 14px;
    background-position: -1005px 0px;
}

.cm {
    height: 14px;
    background-position: -1027px 0px;
}

.cn {
    height: 14px;
    background-position: -1049px 0px;
}

.co {
    height: 14px;
    background-position: -1071px 0px;
}

.cp {
    height: 14px;
    background-position: -1093px 0px;
}

.cr {
    height: 12px;
    background-position: -1115px 0px;
}

.cu {
    height: 10px;
    background-position: -1137px 0px;
}

.cv {
    height: 12px;
    background-position: -1159px 0px;
}

.cw {
    height: 14px;
    background-position: -1181px 0px;
}

.cx {
    height: 10px;
    background-position: -1203px 0px;
}

.cy {
    height: 14px;
    background-position: -1225px 0px;
}

.cz {
    height: 14px;
    background-position: -1247px 0px;
}

.de {
    height: 12px;
    background-position: -1269px 0px;
}

.dg {
    height: 10px;
    background-position: -1291px 0px;
}

.dj {
    height: 14px;
    background-position: -1313px 0px;
}

.dk {
    height: 15px;
    background-position: -1335px 0px;
}

.dm {
    height: 10px;
    background-position: -1357px 0px;
}

.do {
    height: 13px;
    background-position: -1379px 0px;
}

.dz {
    height: 14px;
    background-position: -1401px 0px;
}

.ea {
    height: 14px;
    background-position: -1423px 0px;
}

.ec {
    height: 14px;
    background-position: -1445px 0px;
}

.ee {
    height: 13px;
    background-position: -1467px 0px;
}

.eg {
    height: 14px;
    background-position: -1489px 0px;
}

.eh {
    height: 10px;
    background-position: -1511px 0px;
}

.er {
    height: 10px;
    background-position: -1533px 0px;
}

.es {
    height: 14px;
    background-position: -1555px 0px;
}

.et {
    height: 10px;
    background-position: -1577px 0px;
}

.eu {
    height: 14px;
    background-position: -1599px 0px;
}

.fi {
    height: 12px;
    background-position: -1621px 0px;
}

.fj {
    height: 10px;
    background-position: -1643px 0px;
}

.fk {
    height: 10px;
    background-position: -1665px 0px;
}

.fm {
    height: 11px;
    background-position: -1687px 0px;
}

.fo {
    height: 15px;
    background-position: -1709px 0px;
}

.fr {
    height: 14px;
    background-position: -1731px 0px;
}

.ga {
    height: 15px;
    background-position: -1753px 0px;
}

.gb {
    height: 10px;
    background-position: -1775px 0px;
}

.gd {
    height: 12px;
    background-position: -1797px 0px;
}

.ge {
    height: 14px;
    background-position: -1819px 0px;
}

.gf {
    height: 14px;
    background-position: -1841px 0px;
}

.gg {
    height: 14px;
    background-position: -1863px 0px;
}

.gh {
    height: 14px;
    background-position: -1885px 0px;
}

.gi {
    height: 10px;
    background-position: -1907px 0px;
}

.gl {
    height: 14px;
    background-position: -1929px 0px;
}

.gm {
    height: 14px;
    background-position: -1951px 0px;
}

.gn {
    height: 14px;
    background-position: -1973px 0px;
}

.gp {
    height: 14px;
    background-position: -1995px 0px;
}

.gq {
    height: 14px;
    background-position: -2017px 0px;
}

.gr {
    height: 14px;
    background-position: -2039px 0px;
}

.gs {
    height: 10px;
    background-position: -2061px 0px;
}

.gt {
    height: 13px;
    background-position: -2083px 0px;
}

.gu {
    height: 11px;
    background-position: -2105px 0px;
}

.gw {
    height: 10px;
    background-position: -2127px 0px;
}

.gy {
    height: 12px;
    background-position: -2149px 0px;
}

.hk {
    height: 14px;
    background-position: -2171px 0px;
}

.hm {
    height: 10px;
    background-position: -2193px 0px;
}

.hn {
    height: 10px;
    background-position: -2215px 0px;
}

.hr {
    height: 10px;
    background-position: -2237px 0px;
}

.ht {
    height: 12px;
    background-position: -2259px 0px;
}

.hu {
    height: 10px;
    background-position: -2281px 0px;
}

.ic {
    height: 14px;
    background-position: -2303px 0px;
}

.id {
    height: 14px;
    background-position: -2325px 0px;
}

.ie {
    height: 10px;
    background-position: -2347px 0px;
}

.il {
    height: 15px;
    background-position: -2369px 0px;
}

.im {
    height: 10px;
    background-position: -2391px 0px;
}

.in {
    height: 14px;
    background-position: -2413px 0px;
}

.io {
    height: 10px;
    background-position: -2435px 0px;
}

.iq {
    height: 14px;
    background-position: -2457px 0px;
}

.ir {
    height: 12px;
    background-position: -2479px 0px;
}

.is {
    height: 15px;
    background-position: -2501px 0px;
}

.it {
    height: 14px;
    background-position: -2523px 0px;
}

.je {
    height: 12px;
    background-position: -2545px 0px;
}

.jm {
    height: 10px;
    background-position: -2567px 0px;
}

.jo {
    height: 10px;
    background-position: -2589px 0px;
}

.jp {
    height: 14px;
    background-position: -2611px 0px;
}

.ke {
    height: 14px;
    background-position: -2633px 0px;
}

.kg {
    height: 12px;
    background-position: -2655px 0px;
}

.kh {
    height: 13px;
    background-position: -2677px 0px;
}

.ki {
    height: 10px;
    background-position: -2699px 0px;
}

.km {
    height: 12px;
    background-position: -2721px 0px;
}

.kn {
    height: 14px;
    background-position: -2743px 0px;
}

.kp {
    height: 10px;
    background-position: -2765px 0px;
}

.kr {
    height: 14px;
    background-position: -2787px 0px;
}

.kw {
    height: 10px;
    background-position: -2809px 0px;
}

.ky {
    height: 10px;
    background-position: -2831px 0px;
}

.kz {
    height: 10px;
    background-position: -2853px 0px;
}

.la {
    height: 14px;
    background-position: -2875px 0px;
}

.lb {
    height: 14px;
    background-position: -2897px 0px;
}

.lc {
    height: 10px;
    background-position: -2919px 0px;
}

.li {
    height: 12px;
    background-position: -2941px 0px;
}

.lk {
    height: 10px;
    background-position: -2963px 0px;
}

.lr {
    height: 11px;
    background-position: -2985px 0px;
}

.ls {
    height: 14px;
    background-position: -3007px 0px;
}

.lt {
    height: 12px;
    background-position: -3029px 0px;
}

.lu {
    height: 12px;
    background-position: -3051px 0px;
}

.lv {
    height: 10px;
    background-position: -3073px 0px;
}

.ly {
    height: 10px;
    background-position: -3095px 0px;
}

.ma {
    height: 14px;
    background-position: -3117px 0px;
}

.mc {
    height: 15px;
    background-position: -3139px 0px;
}

.md {
    height: 10px;
    background-position: -3160px 0px;
}

.me {
    height: 10px;
    background-position: -3182px 0px;
}

.mf {
    height: 14px;
    background-position: -3204px 0px;
}

.mg {
    height: 14px;
    background-position: -3226px 0px;
}

.mh {
    height: 11px;
    background-position: -3248px 0px;
}

.mk {
    height: 10px;
    background-position: -3270px 0px;
}

.ml {
    height: 14px;
    background-position: -3292px 0px;
}

.mm {
    height: 14px;
    background-position: -3314px 0px;
}

.mn {
    height: 10px;
    background-position: -3336px 0px;
}

.mo {
    height: 14px;
    background-position: -3358px 0px;
}

.mp {
    height: 10px;
    background-position: -3380px 0px;
}

.mq {
    height: 14px;
    background-position: -3402px 0px;
}

.mr {
    height: 14px;
    background-position: -3424px 0px;
}

.ms {
    height: 10px;
    background-position: -3446px 0px;
}

.mt {
    height: 14px;
    background-position: -3468px 0px;
}

.mu {
    height: 14px;
    background-position: -3490px 0px;
}

.mv {
    height: 14px;
    background-position: -3512px 0px;
}

.mw {
    height: 14px;
    background-position: -3534px 0px;
}

.mx {
    height: 12px;
    background-position: -3556px 0px;
}

.my {
    height: 10px;
    background-position: -3578px 0px;
}

.mz {
    height: 14px;
    background-position: -3600px 0px;
}

.na {
    height: 14px;
    background-position: -3622px 0px;
}

.nc {
    height: 10px;
    background-position: -3644px 0px;
}

.ne {
    height: 15px;
    background-position: -3666px 0px;
}

.nf {
    height: 10px;
    background-position: -3686px 0px;
}

.ng {
    height: 10px;
    background-position: -3708px 0px;
}

.ni {
    height: 12px;
    background-position: -3730px 0px;
}

.nl {
    height: 14px;
    background-position: -3752px 0px;
}

.no {
    height: 15px;
    background-position: -3774px 0px;
}

.np {
    height: 15px;
    background-position: -3796px 0px;
}

.nr {
    height: 10px;
    background-position: -3811px 0px;
}

.nu {
    height: 10px;
    background-position: -3833px 0px;
}

.nz {
    height: 10px;
    background-position: -3855px 0px;
}

.om {
    height: 10px;
    background-position: -3877px 0px;
}

.pa {
    height: 14px;
    background-position: -3899px 0px;
}

.pe {
    height: 14px;
    background-position: -3921px 0px;
}

.pf {
    height: 14px;
    background-position: -3943px 0px;
}

.pg {
    height: 15px;
    background-position: -3965px 0px;
}

.ph {
    height: 10px;
    background-position: -3987px 0px;
}

.pk {
    height: 14px;
    background-position: -4009px 0px;
}

.pl {
    height: 13px;
    background-position: -4031px 0px;
}

.pm {
    height: 14px;
    background-position: -4053px 0px;
}

.pn {
    height: 10px;
    background-position: -4075px 0px;
}

.pr {
    height: 14px;
    background-position: -4097px 0px;
}

.ps {
    height: 10px;
    background-position: -4119px 0px;
}

.pt {
    height: 14px;
    background-position: -4141px 0px;
}

.pw {
    height: 13px;
    background-position: -4163px 0px;
}

.py {
    height: 11px;
    background-position: -4185px 0px;
}

.qa {
    height: 8px;
    background-position: -4207px 0px;
}

.re {
    height: 14px;
    background-position: -4229px 0px;
}

.ro {
    height: 14px;
    background-position: -4251px 0px;
}

.rs {
    height: 14px;
    background-position: -4273px 0px;
}

.ru {
    height: 14px;
    background-position: -4295px 0px;
}

.rw {
    height: 14px;
    background-position: -4317px 0px;
}

.sa {
    height: 14px;
    background-position: -4339px 0px;
}

.sb {
    height: 10px;
    background-position: -4361px 0px;
}

.sc {
    height: 10px;
    background-position: -4383px 0px;
}

.sd {
    height: 10px;
    background-position: -4405px 0px;
}

.se {
    height: 13px;
    background-position: -4427px 0px;
}

.sg {
    height: 14px;
    background-position: -4449px 0px;
}

.sh {
    height: 10px;
    background-position: -4471px 0px;
}

.si {
    height: 10px;
    background-position: -4493px 0px;
}

.sj {
    height: 15px;
    background-position: -4515px 0px;
}

.sk {
    height: 14px;
    background-position: -4537px 0px;
}

.sl {
    height: 14px;
    background-position: -4559px 0px;
}

.sm {
    height: 15px;
    background-position: -4581px 0px;
}

.sn {
    height: 14px;
    background-position: -4603px 0px;
}

.so {
    height: 14px;
    background-position: -4625px 0px;
}

.sr {
    height: 14px;
    background-position: -4647px 0px;
}

.ss {
    height: 10px;
    background-position: -4669px 0px;
}

.st {
    height: 10px;
    background-position: -4691px 0px;
}

.sv {
    height: 12px;
    background-position: -4713px 0px;
}

.sx {
    height: 14px;
    background-position: -4735px 0px;
}

.sy {
    height: 14px;
    background-position: -4757px 0px;
}

.sz {
    height: 14px;
    background-position: -4779px 0px;
}

.ta {
    height: 10px;
    background-position: -4801px 0px;
}

.tc {
    height: 10px;
    background-position: -4823px 0px;
}

.td {
    height: 14px;
    background-position: -4845px 0px;
}

.tf {
    height: 14px;
    background-position: -4867px 0px;
}

.tg {
    height: 13px;
    background-position: -4889px 0px;
}

.th {
    height: 14px;
    background-position: -4911px 0px;
}

.tj {
    height: 10px;
    background-position: -4933px 0px;
}

.tk {
    height: 10px;
    background-position: -4955px 0px;
}

.tl {
    height: 10px;
    background-position: -4977px 0px;
}

.tm {
    height: 14px;
    background-position: -4999px 0px;
}

.tn {
    height: 14px;
    background-position: -5021px 0px;
}

.to {
    height: 10px;
    background-position: -5043px 0px;
}

.tr {
    height: 14px;
    background-position: -5065px 0px;
}

.tt {
    height: 12px;
    background-position: -5087px 0px;
}

.tv {
    height: 10px;
    background-position: -5109px 0px;
}

.tw {
    height: 14px;
    background-position: -5131px 0px;
}

.tz {
    height: 14px;
    background-position: -5153px 0px;
}

.ua {
    height: 14px;
    background-position: -5175px 0px;
}

.ug {
    height: 14px;
    background-position: -5197px 0px;
}

.um {
    height: 11px;
    background-position: -5219px 0px;
}

.us {
    height: 11px;
    background-position: -5241px 0px;
}

.uy {
    height: 14px;
    background-position: -5263px 0px;
}

.uz {
    height: 10px;
    background-position: -5285px 0px;
}

.va {
    height: 15px;
    background-position: -5307px 0px;
}

.vc {
    height: 14px;
    background-position: -5324px 0px;
}

.ve {
    height: 14px;
    background-position: -5346px 0px;
}

.vg {
    height: 10px;
    background-position: -5368px 0px;
}

.vi {
    height: 14px;
    background-position: -5390px 0px;
}

.vn {
    height: 14px;
    background-position: -5412px 0px;
}

.vu {
    height: 12px;
    background-position: -5434px 0px;
}

.wf {
    height: 14px;
    background-position: -5456px 0px;
}

.ws {
    height: 10px;
    background-position: -5478px 0px;
}

.xk {
    height: 15px;
    background-position: -5500px 0px;
}

.ye {
    height: 14px;
    background-position: -5522px 0px;
}

.yt {
    height: 14px;
    background-position: -5544px 0px;
}

.za {
    height: 14px;
    background-position: -5566px 0px;
}

.zm {
    height: 14px;
    background-position: -5588px 0px;
}

.zw {
    height: 10px;
    background-position: -5610px 0px;
}
.billing-info .intl-tel-input {
    width: 100%;
}

#countrySelector {
    max-height: 200px!important;
}

.rbt-input-main {
    font-size: 0.875rem !important;
}

.form-control.is-invalid, .was-validated .form-control:invalid {
    border-color: #dc3545 !important;
}
.no-of-sms {
    position: absolute;
    left: 11.5rem;
}

.sms-credits {
    position: absolute;
    left: 16rem;
}

.total {
    position: absolute;
    left: 18.9rem;
}

.total-email {
    position: absolute;
    left: 16.9rem;
}

.spinner-buttons {
    font-size: .5rem !important;
}

.spinner-buttons.btn-dark {
    color: #212121 !important;
    background-color: transparent !important;
    border-color: transparent !important;
}

.spinner-buttons.btn-dark:hover {
    color: var(--primary) !important;
    background-color: transparent !important;
    border-color: transparent !important;
}

.package-card-1 {
    min-height: 15rem;
}

.package-card-2 {
    min-height: 106px;
}

.ReactVirtualized__Table__headerRow {
    text-transform: capitalize !important;
}

.stripe-card-container {
    height: 60px;
    width: 100% !important;
}

.opacity-p7 {
    opacity: 0.7;
}


.card-payment-container {
    height: 300px;
    overflow-y: scroll;
}

.flag-size-2x {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    margin-bottom: 0.5rem;
    margin-left: 0.5rem;
}

.purchase-box{
    background: #ced1d6;
    color: rgb(0, 0, 0);
    width: 7rem;
    height: 3rem;
    align-items: center;
    display: flex;
    justify-content: center;
    border: 0;
}

.purchase-box.active{
    background: var(--primary);
}

.active-package-label{
    /* position: absolute;
    left: 3.45rem; */
    width: 9rem;
    text-align: center;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    height: 1.75rem;
    align-content: center;
    padding-top: 0.1rem;
    border-radius: 0 0 0.25rem 0.25rem;
    color: #fff;
}


.tick-list-image {
    list-style-image: url(/assets/images/tick-sm.svg);
    /* list-style: none; */
    vertical-align: middle;
    padding-left: 1.3rem !important;
  }

  .pricing-features {
    font-size: 12px;
    text-align: left;
  }

  .per-sms-rate-text{
      font-size: 14.8px;
  }
 
  .badge-platform-offer {
    color: #0089ff;
    background-color: #e0f2ff;
    padding: 0 0.5rem;
    border-radius: 1rem;
}

.opacity-0{
    opacity: 0;
}
.billing-purchase .select-option{
    cursor: pointer;
    border-width: 3px;
}
.billing-purchase .select-option:hover{
    background-color: var(--primary);
    color:#fff;
}

ol.progtrckr {
    margin: 0;
    list-style-type: none;
  }
  ol.progtrckr li {
    display: inline-block;
    cursor: pointer;
  }
  ol.progtrckr li span {
    padding: 0 1.5rem;
  }
  @media (max-width: 650px) {
    .progtrckr li span {
      display: none;
    }
  }
  .progtrckr em {
    display: none;
    font-weight: 700;
    padding-left: 1rem;
  }
  @media (max-width: 650px) {
    .progtrckr em {
      display: inline;
    }
  }

  .progress-line{
    position: relative;
    top: -0.75rem;
    width: 0%;
  }

  ol.progtrckr li.progtrckr-todo .progress-line {
    border-bottom: 4px solid var(--secondary);
    width: 100%;
    transition: all 0.5s 0.5s;
  }

  ol.progtrckr li.progtrckr-todo:last-child .progress-line {
    display: none;
  }

  
  ol.progtrckr li.progtrckr-doing:last-child .progress-line {
    display: none;
  }

  ol.progtrckr li.progtrckr-doing .progress-line {
    border-bottom: 4px solid var(--primary);
    width: 100%;
    transition: all 0.5s 0.5s;
  }

  ol.progtrckr li.progtrckr-done .progress-line {
    border-bottom: 4px solid var(--primary);
    width: 100%;
    transition: all 0.5s 0.5s;
  }


  ol.progtrckr li.progtrckr-todo .step-name {
    color: #fff;
    background: var(--secondary);
    background-position: right bottom;
    padding: 0.25rem 0.75rem;
    border-radius: 1.5rem;

  }
  ol.progtrckr li.progtrckr-doing .step-name {
    color: #fff;
    background: linear-gradient(to left, var(--secondary) 50%, var(--primary) 50%);
    background-size: 200% 100%;
    background-position: left bottom;
   
    padding: 0.25rem 0.75rem;
    border-radius: 1.5rem;
    transition: all 0.5s ease-out;
  }
  ol.progtrckr li.progtrckr-done .step-name {
    color: #fff;
    background: var(--primary);
    padding: 0.25rem 0.75rem;
    border-radius: 1.5rem;
  }


.stripe-card-container{
    height: 60px;
    width:400px;
}
/* HIDE RADIO */
[type=radio] { 
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* IMAGE STYLES */
  [type=radio] + img {
    cursor: pointer;
  }
  
  /* CHECKED STYLES */
  [type=radio]:checked + img {
    outline: 2px solid #f00;
  }

  .card-payment .custom-control-label{
      height: 0 !important;
  }
.notification-bar{
    /* position: fixed!important; */
    top:0;
    left:0;
    z-index: 100;
    width:100%;
}
.inputs input {
    width: 40px;
    height: 40px
}

.pl-verify {
    padding-left: 0.65rem;
}

.top-left-3 {
    top: 2.4rem;
    left: 3rem;
}

.dashboard-cards {
    height: 143px;
}

.page-links {
    text-decoration: none !important;
    color: #ffffff !important;
}

.pointer {
    cursor: pointer;
}

.banner {
    border: 1px solid #3DD660 !important;
    background-color: #c5f3c3 !important;
}

.verify-banner {
    border: 1px solid #3DD660 !important;
    background-color: #c5f3c3 !important;
}

.onboarding-icon {
    width: 5rem;
}

.bg-onboarding {
    background-color: rgba(0, 0, 0, .03);
}

.alert-color-onboarding{
    color: #222222;
    background-color: #FEEDBE;
}
.resend-btn {
    color : #FF1227;
}

.inputs{
    width: 40px;
    height: 40px
}

.otp-digit{
    letter-spacing :0.5rem;
} 

.pointer {
    cursor: pointer;
}

.support{
    color : #00AEFF;
}
.top-nav-bar {
    z-index: 1;
    border-radius: 0 !important;
}

/* .top-nav-bar:after {
    content: " ";
    position: absolute;
    width: calc(100% - 166px);
    background-color: #fff;
    right: 0;
    bottom: 0;
    height: 4px;
    z-index: -1;
    -moz-box-shadow:  0px 4px 5px 0px #e8e8e8;
    -webkit-box-shadow:  0px 4px 5px 0px #e8e8e8;
    box-shadow:  0px 4px 5px 0px #e8e8e8;
} */

.top-nav-bar .nav-components {
    margin-top: 10px;
    padding: 5px;
}

.top-nav-bar .module-link {
    color: #ffffff;
}

.top-nav-bar .module-link:hover {
    color: inherit;
}

.top-nav-bar .module-link.active-link {
    background-color: #6AB3E3;
}

a.nav-link.active {
    color: #000000;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    font-weight: 600;
}

.nav-link {
    padding: 0rem 1rem !important;
}

.main-nav-container {
    position: fixed !important;
    width: 100%;
    /* margin-left: 3.8rem; */
    z-index: 100;
}

.link-text {
    padding: 7px;
    font-size: 12.8px;
    font-weight: 600;
}

.navigation-dropdown{
    font-family: 'Poppins', sans-serif !important;
}
.content-wrapper {
    margin-top: 10%;
}

.login-wrapper {
    margin-top: 10%;
}
html, body, #root, .App {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif !important;
  /* background-color: #f1f3f4; */
  background-color: #F4F7FC;
}

.login-logo img {
  width: 60%;
}

.login-logo {
  margin-bottom: 0;
  margin-top: 15px;
}

.toast-notification {
  z-index: 1051 !important;
}

.toast-notification .success {
  background-color: #4caf50 !important;
}

.navbar-brand:hover {
  cursor: pointer;
}

.nav-pills>li>a {
  border-radius: 0;
}

.text-white {
  color: white;
}

.text-black {
  color: #000;
}

.dropdown-menu>li>a {
  padding: 10px 20px;
}

.dropdown-menu {
  min-width: 100%;
}

/* Breadcumbs */

.breadcrumbs-wrapper {
  padding: 5px;
  border-radius: 4px;
  background-color: #ecf0f1;
  margin: 0 0 8px 0;
}

/* End Breadcumbs */

.cursor-pointer {
  cursor: pointer;
}

.cursor-none {
  cursor: none;
}

/*Temporary theme override*/

.col_fifth, .col_fourth, .col_half, .col_third, .col_three_fourth, .col_twothird {
  margin-right: 2%;
}

.navbar-default .navbar-nav>li>a:focus, .navbar-default .navbar-nav>li>a:hover {
  color: #f2f2f2;
}

.modal-footer {
  padding: 10px;
  background-color: #fff;
}

.modal-content {
  border: 0px solid #00000000 !important;
}

.table.no-border tbody tr td {
  border: none;
}

.table td, .table th {
  padding: 0.5rem;
  vertical-align: middle;
}

.col_fourth:last-child {
  margin-right: 0;
}

.btn-link {
  border: none;
}

.bg-muted {
  background-color: #efefef!important;
}

.btn-link.active, .btn-link:active, .btn-link:hover, .open>.dropdown-toggle.btn-link {
  background-color: inherit;
  color: var(--success);
  border: none;
}

.btn-link.active.focus, .btn-link.active:hover, .btn-link:active.focus, .btn-link:active:focus, .btn-link:active:hover, .open>.dropdown-toggle.btn-link.focus, .open>.dropdown-toggle.btn-link:focus, .open>.dropdown-toggle.btn-link:hover {
  background-color: inherit;
  color: var(--success);
  border: none;
}

/*End temporary theme override*/

.action-link {
  cursor: pointer;
}

.intl-tel-input {
  display: block;
}

.vcenter {
  display: flex;
  align-items: center;
}

.UserAvatar {
  display: inline-block;
  color: white;
}

.fa-2x {
  color: inherit;
}

.top-p40 {
  top: 40%;
}

.left-p45 {
  left: 45%;
}

.margin-top-15 {
  margin-top: 15px;
}

.mt-n45 {
  margin-top: -2.5rem;
}

.natural-text {
  /*show line breaks, tabs etc in the text (\n \t)*/
  white-space: pre;
}

.word-break-all {
  word-break: break-all;
}

.background-ash {
  background-color: gainsboro;
}

.background-white {
  background-color: #ffffff;
}

.padding-30 {
  padding: 30px;
}

.btn-transparent {
  background-color: transparent;
  color: #339bce;
  border: none;
}

.btn-transparent:hover, .btn-transparent:active, .btn-transparent:focus {
  background-color: transparent !important;
  color: #339bce !important;
}

.btn.focus, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #000000;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  font-weight: 600;
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  border-color: #00000000;
  transition: all 150ms;
}

.badge {
  border-radius: 4rem !important;
  font-weight: 300 !important;
  font-size: 73% !important;
  padding: 0.25rem;
}

p {
  margin-bottom: 0rem;
}

.font-weight-semi-bold {
  font-weight: 600!important;
}

/* Add to theme */

.form-group.custom {
  margin-bottom: -0.5rem !important;
}

.top-right {
  top: 0rem;
  right: 0rem;
}

.top-left {
  top: 0rem;
  left: 0rem;
}

.top-left-1 {
  top: 1rem;
  left: 1rem;
}

.card-columns {
  -webkit-column-count: 4;
          column-count: 4;
}

.rounded-circle-left {
  border-radius: 1.25rem 0 0 1.25rem;
}

.rounded-1 {
  border-radius: .2rem;
}

.search-icon {
  width: 29px;
  height: 29px;
  padding: 0;
  margin-left: .5rem;
  margin-top: 0.05rem;
  border-radius: 0.25rem !important;
}

.form-control {
  /* border: none; */
  height: calc(1.5em + 0.6rem);
  font-size: 14px !important;
  padding-left: 0.25rem;
  padding-left: 0.25rem;
}

.bring-to-top {
  z-index: 1000;
}

.people-page.UserAvatar {
  font-size: 1.35rem;
  color: white;
}

.ReactVirtualized__Table__Grid, .ReactVirtualized__List__Grid {
  outline: none;
}

.ReactVirtualized__Table svg {
  vertical-align: baseline;
}

.custom-checkbox .custom-control-label::before {
  margin: 0.15rem 0 0 0;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after, .custom-checkbox .custom-control-input:indeterminate~.custom-control-label::after {
  margin: 0.15rem 0 0 0;
}

.custom-control-label::before {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 0px #00000000;
}

.custom-checkbox .custom-control-input:focus~.custom-control-label::before {
  box-shadow: 0px 0px 0px #00000000;
}

.custom-control-input:focus~.custom-control-label::before {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.custom-switch .custom-control-label::after {
  background-color: var(--dark);
}

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: var(--success);
  background-color: var(--success);
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
  color: #fff;
  background-color: #83ca94;
  border-color: #83ca94;
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
  border-color: var(--muted);
}

.custom-control-label {
  height: 1.5rem;
}

.line-height-1 {
  line-height: 0.9;
}

.fa-fh {
  height: 1.25em;
}

.form-control:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.input-group {
  padding-top: 0;
}

.height-inherit {
  height: inherit;
}

.dashed {
  border-top: 1px dashed #bfbfbf;
}

.apply-filter-button {
  border-radius: 1rem;
}

.shadow {
  box-shadow: 0 0.5rem 1rem #e6e6e6 !important;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0;
}

.extra-small {
  font-size: 70%;
}

.rbt-input {
  /* border-radius: 0; */
  font-size: 14px !important;
  width: 100% !important;
  box-shadow: 0 0 0 0 #00000000 !important;
  /* border-color: #00000000 !important; */
  border: 1px solid #ced4da !important;
  /* border-bottom: px solid #c0bebe !important; */
  transition: all 0.3s ease-in;
}

.rbt-input:focus {
  border-bottom: 1px solid #ced4da !important;
}

.dropdown-menu {
  font-size: 14px !important;
}

.disabled {
  opacity: 0.65;
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
  pointer-events: none;
}

/* mobile number input custom validation styles */

.intl-tel-input .is-invalid {
  border-color: "#dc3545" !important;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") !important;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
}

/* end */

@-webkit-keyframes onload {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes onload {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.onload-transition {
  -webkit-animation: 1s ease-out 0s 1 onload;
          animation: 1s ease-out 0s 1 onload;
}

.padding-top-container {
  padding-top: 1rem;
}

.multistep__btn--next, .multistep__btn--prev {
  width: 8rem !important;
}

.btn-outline-pink {
  color: var(--pink);
  border-color: var(--pink);
}

.btn-outline-pink:hover {
  color: #fff;
  background-color: var(--pink);
  border-color: var(--pink);
}

.btn-outline-orange {
  color: var(--orange);
  border-color: var(--orange);
}

.btn-outline-orange:hover {
  color: #fff;
  background-color: var(--purple);
  border-color: var(--orange);
}

.btn-outline-purple {
  color: var(--purple);
  border-color: var(--purple);
}

.btn-outline-purple:hover {
  color: #fff;
  background-color: var(--purple);
  border-color: var(--purple);
}

.badge-pink {
  color: var(--pink) !important;
  background-color: #ffe7f2 !important;
}

.badge-purple {
  color: var(--purple) !important;
  background-color: #f1eaff !important;
}

.badge-success {
  color: #069026 !important;
  background-color: #d0ffdb !important;
}

.badge-secondary {
  color: #1c1f21 !important;
  background-color: #e8e8e8 !important;
}

.badge-warning {
  color: #b38b15 !important;
  background-color: #fff3d0 !important;
}

.badge-danger {
  color: #fa5c7c;
  background-color: #ffe4ea;
}

.badge-primary{
  color: #00aeff;
  background-color: #dff5ff;
}

.badge-tags {
  color: #fff;
  background-color: #343a40;
}

.input-group>.custom-select:not(:last-child), .input-group>.form-control:not(:last-child) {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  border-radius: 6px;
  background: #fbfbfb;
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: #c0c0c0;
}

.international-numbers {
  background: #dff5ff;
  margin-left: 1rem;
  padding: 0.3rem;
  color: #00aeff;
  line-height: 0.7;
}

.sender-card {
  background-color: white;
  border-radius: calc(.25rem - 1px) !important;
  border: 0 !important;
}

.senders-tab-height {
  height: 80vh;
  overflow-y: auto;
  margin-top: 1rem;
}

.bg-artifacts {
  background: #efefef6b;
  padding: 0.1rem 0.25rem;
}

.w-80 {
  width: 80%;
}

button:focus {
  outline: 0px dotted !important;
  outline: 0px auto -webkit-focus-ring-color !important;
}

/* button {
  height: 29px !important;
  line-height: 0.8 !important; 
} */

.custom-line-chart-container {
  margin-left: -3rem;
  margin-top: 2rem;
}

.billing-purchase-height {
  height: 50rem;
  overflow-y: scroll;
}

.modal-header {
  color: #212529 !important;
}

.border-bottom-navigation {
  border-bottom: 1px solid #00aeff38!important;
}

/* .border-right-navigation {
  border-right: 1px solid #00aeff38!important;
} */

.dropdown-item.active, .dropdown-item:active {
  color: var(--primary) !important;
  background-color: transparent !important;
}

.sms-sender-button {
  -webkit-appearance: none;
  border-radius: .2rem;
}

.tag-0 {
  color: #006935;
  background-color: #ddffe0;
}

.tag-1 {
  color: #5a3dfa;
  background-color: #e8e4ff;
}

.tag-2 {
  color: #0089ff;
  background-color: #e0f2ff;
}

.tag-3 {
  color: #cf2488;
  background-color: #ffdff2;
}

.tag-4 {
  color: #e05a00;
  background-color: #ffe2cf;
}

.tag-undefined {
  color: #960606;
  background-color: #ffdddd;
}

/* 
.iti-mobile .intl-tel-input .country-list {
  max-height: none !important; 
  width: 100%;
} */

/* .iti-mobile .intl-tel-input .country-list {
  max-height: 200px !important; 
} */

.overflow-unset {
  overflow: unset;
}

.something-went-wrong-margin {
  margin-top: 12rem;
}

.ml-n25{
  margin-left: -0.7rem!important;
}
