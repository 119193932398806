@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
html, body, #root, .App {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif !important;
  /* background-color: #f1f3f4; */
  background-color: #F4F7FC;
}

.login-logo img {
  width: 60%;
}

.login-logo {
  margin-bottom: 0;
  margin-top: 15px;
}

.toast-notification {
  z-index: 1051 !important;
}

.toast-notification .success {
  background-color: #4caf50 !important;
}

.navbar-brand:hover {
  cursor: pointer;
}

.nav-pills>li>a {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.text-white {
  color: white;
}

.text-black {
  color: #000;
}

.dropdown-menu>li>a {
  padding: 10px 20px;
}

.dropdown-menu {
  min-width: 100%;
}

/* Breadcumbs */

.breadcrumbs-wrapper {
  padding: 5px;
  border-radius: 4px;
  background-color: #ecf0f1;
  margin: 0 0 8px 0;
}

/* End Breadcumbs */

.cursor-pointer {
  cursor: pointer;
}

.cursor-none {
  cursor: none;
}

/*Temporary theme override*/

.col_fifth, .col_fourth, .col_half, .col_third, .col_three_fourth, .col_twothird {
  margin-right: 2%;
}

.navbar-default .navbar-nav>li>a:focus, .navbar-default .navbar-nav>li>a:hover {
  color: #f2f2f2;
}

.modal-footer {
  padding: 10px;
  background-color: #fff;
}

.modal-content {
  border: 0px solid #00000000 !important;
}

.table.no-border tbody tr td {
  border: none;
}

.table td, .table th {
  padding: 0.5rem;
  vertical-align: middle;
}

.col_fourth:last-child {
  margin-right: 0;
}

.btn-link {
  border: none;
}

.bg-muted {
  background-color: #efefef!important;
}

.btn-link.active, .btn-link:active, .btn-link:hover, .open>.dropdown-toggle.btn-link {
  background-color: inherit;
  color: var(--success);
  border: none;
}

.btn-link.active.focus, .btn-link.active:hover, .btn-link:active.focus, .btn-link:active:focus, .btn-link:active:hover, .open>.dropdown-toggle.btn-link.focus, .open>.dropdown-toggle.btn-link:focus, .open>.dropdown-toggle.btn-link:hover {
  background-color: inherit;
  color: var(--success);
  border: none;
}

/*End temporary theme override*/

.action-link {
  cursor: pointer;
}

.intl-tel-input {
  display: block;
}

.vcenter {
  display: flex;
  align-items: center;
}

.UserAvatar {
  display: inline-block;
  color: white;
}

.fa-2x {
  color: inherit;
}

.top-p40 {
  top: 40%;
}

.left-p45 {
  left: 45%;
}

.margin-top-15 {
  margin-top: 15px;
}

.mt-n45 {
  margin-top: -2.5rem;
}

.natural-text {
  /*show line breaks, tabs etc in the text (\n \t)*/
  white-space: pre;
}

.word-break-all {
  word-break: break-all;
}

.background-ash {
  background-color: gainsboro;
}

.background-white {
  background-color: #ffffff;
}

.padding-30 {
  padding: 30px;
}

.btn-transparent {
  background-color: transparent;
  color: #339bce;
  border: none;
}

.btn-transparent:hover, .btn-transparent:active, .btn-transparent:focus {
  background-color: transparent !important;
  color: #339bce !important;
}

.btn.focus, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #000000;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  font-weight: 600;
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  border-color: #00000000;
  transition: all 150ms;
}

.badge {
  border-radius: 4rem !important;
  font-weight: 300 !important;
  font-size: 73% !important;
  padding: 0.25rem;
}

p {
  margin-bottom: 0rem;
}

.font-weight-semi-bold {
  font-weight: 600!important;
}

/* Add to theme */

.form-group.custom {
  margin-bottom: -0.5rem !important;
}

.top-right {
  top: 0rem;
  right: 0rem;
}

.top-left {
  top: 0rem;
  left: 0rem;
}

.top-left-1 {
  top: 1rem;
  left: 1rem;
}

.card-columns {
  column-count: 4;
}

.rounded-circle-left {
  border-radius: 1.25rem 0 0 1.25rem;
}

.rounded-1 {
  border-radius: .2rem;
}

.search-icon {
  width: 29px;
  height: 29px;
  padding: 0;
  margin-left: .5rem;
  margin-top: 0.05rem;
  border-radius: 0.25rem !important;
}

.form-control {
  /* border: none; */
  height: calc(1.5em + 0.6rem);
  font-size: 14px !important;
  padding-left: 0.25rem;
  padding-left: 0.25rem;
}

.bring-to-top {
  z-index: 1000;
}

.people-page.UserAvatar {
  font-size: 1.35rem;
  color: white;
}

.ReactVirtualized__Table__Grid, .ReactVirtualized__List__Grid {
  outline: none;
}

.ReactVirtualized__Table svg {
  vertical-align: baseline;
}

.custom-checkbox .custom-control-label::before {
  margin: 0.15rem 0 0 0;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after, .custom-checkbox .custom-control-input:indeterminate~.custom-control-label::after {
  margin: 0.15rem 0 0 0;
}

.custom-control-label::before {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 0px #00000000;
}

.custom-checkbox .custom-control-input:focus~.custom-control-label::before {
  box-shadow: 0px 0px 0px #00000000;
}

.custom-control-input:focus~.custom-control-label::before {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.custom-switch .custom-control-label::after {
  background-color: var(--dark);
}

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: var(--success);
  background-color: var(--success);
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
  color: #fff;
  background-color: #83ca94;
  border-color: #83ca94;
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
  border-color: var(--muted);
}

.custom-control-label {
  height: 1.5rem;
}

.line-height-1 {
  line-height: 0.9;
}

.fa-fh {
  height: 1.25em;
}

.form-control:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.input-group {
  padding-top: 0;
}

.height-inherit {
  height: inherit;
}

.dashed {
  border-top: 1px dashed #bfbfbf;
}

.apply-filter-button {
  border-radius: 1rem;
}

.shadow {
  box-shadow: 0 0.5rem 1rem #e6e6e6 !important;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0;
}

.extra-small {
  font-size: 70%;
}

.rbt-input {
  /* border-radius: 0; */
  font-size: 14px !important;
  width: 100% !important;
  box-shadow: 0 0 0 0 #00000000 !important;
  /* border-color: #00000000 !important; */
  border: 1px solid #ced4da !important;
  /* border-bottom: px solid #c0bebe !important; */
  transition: all 0.3s ease-in;
}

.rbt-input:focus {
  border-bottom: 1px solid #ced4da !important;
}

.dropdown-menu {
  font-size: 14px !important;
}

.disabled {
  opacity: 0.65;
  filter: grayscale(1);
  pointer-events: none;
}

/* mobile number input custom validation styles */

.intl-tel-input .is-invalid {
  border-color: "#dc3545" !important;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") !important;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
}

/* end */

@keyframes onload {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.onload-transition {
  animation: 1s ease-out 0s 1 onload;
}

.padding-top-container {
  padding-top: 1rem;
}

.multistep__btn--next, .multistep__btn--prev {
  width: 8rem !important;
}

.btn-outline-pink {
  color: var(--pink);
  border-color: var(--pink);
}

.btn-outline-pink:hover {
  color: #fff;
  background-color: var(--pink);
  border-color: var(--pink);
}

.btn-outline-orange {
  color: var(--orange);
  border-color: var(--orange);
}

.btn-outline-orange:hover {
  color: #fff;
  background-color: var(--purple);
  border-color: var(--orange);
}

.btn-outline-purple {
  color: var(--purple);
  border-color: var(--purple);
}

.btn-outline-purple:hover {
  color: #fff;
  background-color: var(--purple);
  border-color: var(--purple);
}

.badge-pink {
  color: var(--pink) !important;
  background-color: #ffe7f2 !important;
}

.badge-purple {
  color: var(--purple) !important;
  background-color: #f1eaff !important;
}

.badge-success {
  color: #069026 !important;
  background-color: #d0ffdb !important;
}

.badge-secondary {
  color: #1c1f21 !important;
  background-color: #e8e8e8 !important;
}

.badge-warning {
  color: #b38b15 !important;
  background-color: #fff3d0 !important;
}

.badge-danger {
  color: #fa5c7c;
  background-color: #ffe4ea;
}

.badge-primary{
  color: #00aeff;
  background-color: #dff5ff;
}

.badge-tags {
  color: #fff;
  background-color: #343a40;
}

.input-group>.custom-select:not(:last-child), .input-group>.form-control:not(:last-child) {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  border-radius: 6px;
  background: #fbfbfb;
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: #c0c0c0;
}

.international-numbers {
  background: #dff5ff;
  margin-left: 1rem;
  padding: 0.3rem;
  color: #00aeff;
  line-height: 0.7;
}

.sender-card {
  background-color: white;
  border-radius: calc(.25rem - 1px) !important;
  border: 0 !important;
}

.senders-tab-height {
  height: 80vh;
  overflow-y: auto;
  margin-top: 1rem;
}

.bg-artifacts {
  background: #efefef6b;
  padding: 0.1rem 0.25rem;
}

.w-80 {
  width: 80%;
}

button:focus {
  outline: 0px dotted !important;
  outline: 0px auto -webkit-focus-ring-color !important;
}

/* button {
  height: 29px !important;
  line-height: 0.8 !important; 
} */

.custom-line-chart-container {
  margin-left: -3rem;
  margin-top: 2rem;
}

.billing-purchase-height {
  height: 50rem;
  overflow-y: scroll;
}

.modal-header {
  color: #212529 !important;
}

.border-bottom-navigation {
  border-bottom: 1px solid #00aeff38!important;
}

/* .border-right-navigation {
  border-right: 1px solid #00aeff38!important;
} */

.dropdown-item.active, .dropdown-item:active {
  color: var(--primary) !important;
  background-color: transparent !important;
}

.sms-sender-button {
  -webkit-appearance: none;
  -webkit-border-radius: none;
  border-radius: .2rem;
}

.tag-0 {
  color: #006935;
  background-color: #ddffe0;
}

.tag-1 {
  color: #5a3dfa;
  background-color: #e8e4ff;
}

.tag-2 {
  color: #0089ff;
  background-color: #e0f2ff;
}

.tag-3 {
  color: #cf2488;
  background-color: #ffdff2;
}

.tag-4 {
  color: #e05a00;
  background-color: #ffe2cf;
}

.tag-undefined {
  color: #960606;
  background-color: #ffdddd;
}

/* 
.iti-mobile .intl-tel-input .country-list {
  max-height: none !important; 
  width: 100%;
} */

/* .iti-mobile .intl-tel-input .country-list {
  max-height: 200px !important; 
} */

.overflow-unset {
  overflow: unset;
}

.something-went-wrong-margin {
  margin-top: 12rem;
}

.ml-n25{
  margin-left: -0.7rem!important;
}